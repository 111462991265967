<template>
  <div class="cultural container">
    <div class="cultural-left">
      <!-- img -->
      <div class="imgbox"
           v-if="dataObj.picList && dataObj.type !== 2">
        <GoodsImgs :images="dataObj.picList " />
      </div>
      <div class="imgbox">
        <XgVdieoPlay v-if="dataObj.type === 2"
                     :key="dataObj.thumbnail"
                     :pathUrl="$addUrlPrefix(dataObj.thumbnail)"
                     :otherConfig="{
                      videoInit: true,
                      fluid: true,
                      playbackRate: [0.5, 0.75, 1, 1.5, 2],
                      defaultPlaybackRate: 1,
                      pip: true,
                      screenShot: {
                      saveImg: true,
                      quality: 0.92,
                      type: 'image/png',
                      format: '.png'
                      }
                    }" />
      </div>

      <div class="title">{{dataObj.title}}</div>
      <div class="text-box"
           v-html="dataObj.content"></div>
      <div class="reply-area">
        <div class="reply-title">笔记评论</div>
        <template v-if="commentList.length">
          <div class="reply-main"
               v-for="(item,index) in commentList"
               :key="index">
            <div class="reply-heard">
              <div class="user-info">
                <img class="user-aver"
                     v-lazy="item.userLogo" />
                <div class="user-main">
                  <div class="user-name">{{item.nickname}}</div>
                  <div class="user-date">{{$timestampFormat(item.createTime,'yy-mm-dd')}}</div>
                </div>
              </div>
              <div class="reply-tag"
                   @click="onDialog">
                <i class="zan-icon iconfont-p icon-dianzan"></i>
                <div class="zan-num">{{item.likeNumber}}</div>
                回复
              </div>
            </div>
            <div class="reply-content">
              <div class="reply-content-main">{{item.content}}</div>
              <!-- <div class="reply-content-other">
              <div class="reply-other-item">网友回复+{{item.replyNum}}</div>
            </div> -->
            </div>
          </div>
        </template>
        <qdzEmpty v-else
                  state="notData" />
      </div>
    </div>
    <div class="cultural-right">
      <div class="author-info">
        <div class="author-brief">
          <img class="author-portrait"
               :src="$addUrlPrefix( dataObj.authorLogo && (dataObj.authorLogo + '').indexOf('null') > -1 ? 'https://qdz.hsz88.com/static/logo-two.png' : dataObj.authorLogo)"
               :alt="info.nickName" />
          <div class="author-name">{{info.nickName}}</div>
          <div class="brief-bar">
            <div class="brief-bar-title">
              个人简介：
            </div>
            <div class="brief-bar-content">{{info.brief || '这个人很懒一点没留下'}}</div>
          </div>

        </div>
        <div class="author-follow-bar">
          <div class="author-follow-item">
            <div class="follow-num">{{ $readNumFormat(info.allowComment ?? 0)}}</div>
            <div class="follow-title">关注</div>
          </div>
          <div class="author-follow-item">
            <div class="follow-num">{{$readNumFormat(info.followNum ?? 0) }}</div>
            <div class="follow-title">粉丝</div>
          </div>
          <div class="author-follow-item">
            <div class="follow-num">{{$readNumFormat(info.thumbsNum ?? 0)}}</div>
            <div class="follow-title">获赞与收藏</div>
          </div>
        </div>
      </div>
      <div class="relation"
           v-if="arList.length">
        <div class="relation-title">
          相关内容
        </div>
        <div class="relation-list">
          <div class="relation-list-item"
               v-for="item in arList"
               :key="item"
               @click="$router.push('/culturalCenter/detail?contentId='+item.id)">

            <img class="item-pic"
                 v-lazy="item.type == 1 ? item.thumbnail : item.videoCover"
                 alt="">
            <div class="item-content">
              <div class="content-title ellipsis">{{item.title}}</div>
              <div class="content-follow">{{$readNumFormat(item.viewNumber  || 0)}}阅读</div>
            </div>

          </div>
        </div>
        <div class="see-more"
             @click="onDialog">
          查看更多>>
        </div>
      </div>
    </div>
  </div>
  <Dialog :visible="visible"
          @close="onClose">
    <div class="dialog-content"
         @click="onClose">
      <img class="qrcode"
           :src="shareUrl"
           alt="">
    </div>
  </Dialog>
</template>

<script setup>
import { toRefs, reactive, watchEffect } from 'vue'
import { useRoute } from 'vue-router'
import { getCultureContentDetail, getCultureTopicComments, getCultureUserStatisticsPc, getUserCultureListPc } from '@/api/culturalCenter'
import { ApiGetAppletsShareCode } from '@/api/goods'
import GoodsImgs from './components/goodsImgs.vue'
import XgVdieoPlay from './components/xgVdieoPlay.vue'
import Dialog from './components/dialog.vue'

const route = useRoute()
const {
  dataObj,
  commentList,
  info,
  shareUrl,
  arList,
  visible
} = toRefs(reactive({
  dataObj: {},
  commentList: [],
  info: {},
  shareUrl: '',
  arList: [],
  visible: false
}))


const getDetail = (contentId) => {
  getCultureContentDetail({ contentId }).then(res => {
    // console.log(res)
    if (res.code === '10000') {
      dataObj.value = res.data
      getInfoPc(res.data.authorId)
      getList({
        followUserId: res.data.authorId,
        pageNum: 1, pageSize: 5, type: 0
      })
    }
  })
}

const getTopicComments = (contentId) => {
  getCultureTopicComments({ contentId }).then(res => {
    // console.log(res)
    if (res.code === '10000') {
      commentList.value = res.data.list
    }
  })
}
const getInfoPc = (followUserId) => {
  getCultureUserStatisticsPc({ followUserId }).then(res => {
    // console.log('关注', res)
    info.value = res.data
  })
}
const getList = (data) => {
  getUserCultureListPc(data).then(res => {
    // console.log('re', res)
    arList.value = res.data.list
  })
}


const onDialog = () => {
  // console.log(visible.value)
  visible.value = !visible.value;
  if (shareUrl.value == '') handleGetShareCode()

};

const onClose = () => {
  visible.value = false;
};
const handleGetShareCode = async () => {
  let res = await ApiGetAppletsShareCode({
    page: dataObj.value.type == 1 ? 'page_cultural/pages/theme/themeDetail' : 'page_cultural/pages/videoPage',
    params: 'contentId=' + route.query.contentId
  })
  // console.log(res.data)
  shareUrl.value = res.data.shareUrl
}

watchEffect(() => {
  const { contentId } = route.query
  getDetail(contentId)
  getTopicComments(contentId)
  shareUrl.value = ''
})
</script>
<style lang="less" scoped>
.cultural {
  display: flex;
  padding: 30px 0 130px 160px;

  .cultural-left {
    width: 582px;
    // background-color: #fff;
    margin-right: 40px;
    height: auto;
    .imgbox {
      width: 582px;
      // height: 600px;
      // background-color: #fff;
      margin-bottom: 20px;
    }
    .title {
      font-size: 17px;
      font-weight: bold;
      color: #333333;
      margin-bottom: 38px;
    }
    .text-box {
      height: auto;
      margin-bottom: 30px;
    }
    .reply-area {
      background-color: #fff;

      .reply-title {
        height: 81px;
        padding-top: 39px;
        padding-left: 17px;
        position: relative;
        letter-spacing: 1px;
        &::before {
          content: "";
          display: block;
          position: absolute;
          left: 0;
          width: 5px;
          height: 17px;
          // background: #fadc54;
        }
      }
      .reply-main {
        padding: 19px 17px;
        border-bottom: 1px solid #dfe0e3;
        .reply-heard {
          height: 50px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .user-info {
            width: fit-content;
            display: flex;
            align-items: center;
            .user-aver {
              width: 34px;
              height: 34px;
              background: #d6b177;
              border-radius: 50%;
              margin-right: 10px;
            }
            .user-main {
              width: fit-content;
              .user-name {
                width: fit-content;
                height: 23px;
                font-size: 15px;
                font-weight: 500;
                color: #5f6674;
              }
              .user-date {
                height: 23px;
                font-size: 13px;
                font-weight: 400;
                color: #dfe0e3;
              }
            }
          }
          .reply-tag {
            display: flex;
            font-size: 15px;
            font-weight: 400;
            color: #dfe0e3;
            align-items: center;
            cursor: pointer;
            .zan-icon {
              // width: 22px;
              // height: 22px;
              // background-color: yellow;
              font-size: 22px;
            }
            .zan-num {
              margin: 0 6px;
            }
          }
        }
        .reply-content {
          width: 463px;
          margin: 0 auto;
          .reply-content-main {
            font-size: 15px;
            font-weight: 500;
            color: #111111;
            margin-bottom: 10px;
          }
          .reply-content-other {
            padding: 19px 10px;
            background: #fafcff;
            .reply-other-item {
              height: 20px;
              font-size: 15px;
              font-weight: 400;
              color: #5f6674;
              margin-bottom: 22px;
            }
          }
        }
      }
    }
  }
  .cultural-right {
    width: 322px;
    height: fit-content;
    .author-info {
      padding-top: 25px;
      margin-bottom: 20px;
      background-color: #fff;

      .author-brief {
        width: 100%;
        height: 206px;
        border-bottom: 1px dashed #dadada;

        .author-portrait {
          width: 71px;
          height: 71px;
          display: block;
          background: #d6b177;
          border-radius: 50%;
          overflow: hidden;
          margin: 0 auto 12px;
        }
        .author-name {
          width: fit-content;
          font-size: 18px;
          font-weight: bold;
          color: #333333;
          margin: 0 auto 18px;
          font-family: "Source Han Sans CN-Bold";
        }
        .brief-bar {
          display: flex;
          width: 283px;
          margin: 0 auto;
          // justify-content: space-between;
          font-size: 13px;
          font-weight: 400;
          color: #bec1c7;
          .brief-bar-title {
            width: 70px;
            flex-shrink: 0;
          }
          .brief-bar-content {
            text-align: center;
            letter-spacing: 1px;
          }
        }
      }
      .author-follow-bar {
        display: flex;
        height: 104px;
        align-items: center;
        .author-follow-item {
          width: 33%;
          .follow-num {
            font-size: 20px;
            font-weight: bold;
            text-align: center;
          }
          .follow-title {
            font-size: 14px;
            font-weight: 400;
            color: #bec1c7;
            text-align: center;
          }
        }
      }
    }
    .relation {
      width: 322px;
      max-height: 553px;
      background: #ffffff;
      .relation-title {
        height: 54px;
        line-height: 54px;
        font-size: 17px;
        font-weight: bold;
        color: #111111;
        border-bottom: 1px solid #dfe0e3;
        padding-left: 19px;
      }
      .relation-list {
        padding: 19px;
        border-bottom: 1px solid #dfe0e3;
        .relation-list-item {
          width: 100%;
          height: 58px;
          margin-bottom: 28px;
          display: flex;
          cursor: pointer;
          &:last-child {
            margin-bottom: 0;
          }
          .item-pic {
            width: 58px;
            height: 58px;
            flex-shrink: 0;
            margin-right: 12px;
            background-color: #eee;
          }
          .item-content {
            width: 213px;
            .content-title {
              font-size: 14px;
              font-weight: 500;
              color: #5f6674;
              margin-bottom: 10px;
            }
            .content-follow {
              font-size: 13px;
              font-weight: 400;
              color: #dfe0e3;
            }
          }
        }
      }
      .see-more {
        text-align: center;
        height: 60px;
        line-height: 60px;
        font-size: 13px;
        font-weight: 400;
        color: #111111;
      }
    }
  }
}
.dialog-content {
  width: 480px;
  height: 547px;
  background-image: url(../../assets/images/cultural/dialog.png);
  background-position: center;
  background-repeat: no-repeat;
  // background-size: cover;
  position: relative;
  .qrcode {
    width: 110px;
    height: 110px;
    background-color: #fff;
    position: absolute;
    top: 310px;
    right: 111px;
  }
}
</style>