<template>
  <div id="vs"></div>
</template>
<script>
import { onMounted } from 'vue'
import Player from 'xgplayer'
// 其他配置参数参数 请参考西瓜视频播放器文档 https://v2.h5player.bytedance.com/
export default {
  props: {
    pathUrl: {
      type: String,
      default: () => ''
    },
    otherConfig: {
      type: Object,
      default: () => { }
    }
  },
  setup (props) {
    onMounted(() => {
      let player = new Player({
        id: 'vs',
        url: props.pathUrl,
        ...props.otherConfig
      })
    })
  }
}
</script>