// 定义商品模块需要的接口函数
import request from '@/utils/request'
// 接口参考H5版本.  

/**
 * 商品模块 - 获取商品详情
 * @returns Promise
 */
export const ApiGetGoodsDetail = (data) => {
  return request('/honghu-goods-service/detil/goods/get', 'get', data)
}


/**
 * 商品模块 - 生成小程序二维码
 * @returns Promise
 */
export const ApiGetAppletsShareCode = (data) => {
  return request('/honghu-file-service/qrCode/getAppletsShareCode', 'get', data)
}

/**
 * 商品模块 - 生成小程序二维码加码
 * @returns Promise
 */
export const ApiAddParamSign = (data) => {
  return request('/honghu-common-service/sysConfig/addParamSign', 'post', data)
}


